import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { WDWDLRQQAnalyticsLinkParams } from '@interfaces/cruise-listing-analytics-model';
import { ConfigService } from '@app/core/config.service';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTS } from '@app/app.constants';
import { get, debounce } from 'lodash-es';
import { LoggerService } from "@wdpr/ra-angular-logger";
import { CookieService } from 'ngx-cookie-service';
import { TIME } from '@app/app.constants';
import getLangFromOrigin from '@app/shared/utils/string/getLangFromOrigin';

@Component({
    selector: 'pixie-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {

    id = '';
    title = '';
    ctas = '';
    siteId: string;
    baseUrl: string;
    utilsConfig;
    fastStyle : boolean;
    qqFastSparkleLine: boolean;
    recommendationModel: any = {};
    authToken;
    isMobileOrTablet;

    constructor(
        private configService: ConfigService,
        private analyticsService: HomepageAnalyticsService,
        private httpClient: HttpClient,
        private _logger: LoggerService,
        private cookieService: CookieService,
    ) { }

    ngOnInit() {
        try {
            this.initWDWQuickQuoteConfig();
            this.siteId = this.configService.getValue('siteId');
            const toggles = this.configService.getValue('toggles');
            const currentLanguage = getLangFromOrigin();
            
            if (toggles?.fastTheme?.[currentLanguage] === true) {
                this.fastStyle = true;
                if (toggles.fastQuickQuoteSparkleLine === true) {
                    this.qqFastSparkleLine = true;
                } else {
                    this.qqFastSparkleLine = false;
                }
            } else {
                this.fastStyle = false;
            }

            this.orderElements();

            window.addEventListener('resize', debounce(() => {
                this.orderElements();
            }, 100));
        } catch(err) {
            this._logger.error('Error initializing homepage wdw, ',err);
        }
    }

    /** Places elements in the correct order in the DOM so that keyboard navigation order matches */
    orderElements = () => {
        const homepageTop = document.getElementById('homepage-top');
        const qqContainer = document.getElementById('quick-quote-container');
        const pixieHero = document.getElementsByTagName('pixie-hero')[0];
        const windowSize = window.innerWidth;
        if (windowSize >= 1024) { // Desktop size
            homepageTop.prepend(qqContainer);
        } else { // Mobile size
            homepageTop.prepend(pixieHero);
        }

        if (document.body.classList.contains('showfast')) {
            const fastRoundedSpacer = document.getElementsByClassName('stories-rounded-spacer')[0];
            if (windowSize >= 1024) { // Desktop size
                this.isMobileOrTablet = false;
            } else { // Mobile size
                qqContainer.prepend(fastRoundedSpacer);
            }
        }
    }
    
    // Listen for p13n response
    @HostListener('document:personalization.responseReady', ['$event'])
    onP13nReady(event) {
        try {
            const locations = get(event, 'detail.locations');
            if (!locations) {
                this._logger.error('PERSONALIZATION service -- No locations found');

                return;
            }

            // Set service properties
            this.recommendationModel.locations = locations;
        } catch(err) {
            this._logger.error('Error listening to p13n: ',err);
        }
    }

    /**
     * Receives the analytics data from the respective triggered Event Emitter
     * @param analyticsData object with the values needed for Analytics Function.
     */
    analyticsTrackingEvent = (linkId: string, analyticsData?: WDWDLRQQAnalyticsLinkParams) => {
        this.analyticsService.trackLink(linkId, analyticsData);
    }

    /**
     * Make call to utils and d-scribe to get the configs and contents for QQ
     */
    public initWDWQuickQuoteConfig(): void {
        this.httpClient.get(
            `${ENDPOINTS.utilsConfig}`
        ).subscribe((config) => {
            this.utilsConfig = config;
            document.querySelector('body').classList.add('qq-ready');
        });
    }
}
