  <ng-container *ngIf="showComponent">
    <div id="fast-florida-targeted-affiliation">
      <ng-container 
      *ngIf="(isFloridaResidentProfile || isFloridaResidentAffl) && !hasClickedButton && !isConfirmedFlResident">
        <div class="fast-florida-targeted-affiliation">
          <span id="flres" class="targeted-affiliation-group text">
            <span class="targeted-affiliation-icon">
              <img class="icon" aria-hidden="true" [src]="iconUrl" />
            </span>
            <span class="targeted-affiliation-text">
              <p>{{targetAffiliationContent.text}}</p>
            </span>
          </span>
          <span class="targeted-affiliation-group buttons">
            <div role="group" aria-labelledby="flres">
              <ng-container *ngFor="let button of confirmationButtons | keyvalue">
                <span class="targeted-affiliation-button">
                  <button (click)="onButtonClick(button.key)"
                    class="snowball-button snowball-button-theme-white color-sky-blue last" target="_self"
                    name="&amp;lid=targeted_affil_module_button-{{button.value}}">
                    {{button.value}}
                  </button>
                </span>
              </ng-container>
            </div>
          </span>
          <span class="parkicons">
            <span class="iconsgroup">
              <img class="parkicon one" aria-hidden="true" src="https://cdn1.parksmedia.wdprapps.disney.com/media/testopt/homepage-uplift/animal-kingdom.svg">
              <img class="parkicon two" aria-hidden="true" src="https://cdn1.parksmedia.wdprapps.disney.com/media/testopt/homepage-uplift/magic-kingdom.svg">
              <img class="parkicon three" aria-hidden="true" src="https://cdn1.parksmedia.wdprapps.disney.com/media/testopt/homepage-uplift/hollywood-studios.svg">
              <img class="parkicon four" aria-hidden="true" src="https://cdn1.parksmedia.wdprapps.disney.com/media/testopt/homepage-uplift/epcot.svg">
            </span>
          </span>
        </div>
      </ng-container>
      <p *ngIf="isConfirmedFlResident" id="confirmationText" [class.faded-text]="!isConfirmedFlResident">
        {{targetAffiliationContent.confirmationText}}
      </p>
    </div>
  </ng-container>
  