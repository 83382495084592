import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@core/config.service';
import { Link } from '@core/interfaces/link';
import { get } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import getLangFromOrigin from '@app/shared/utils/string/getLangFromOrigin';

@Component({
  selector: 'pixie-florida-affiliation',
  templateUrl: './florida-affiliation.component.html',
  styleUrls: ['./florida-affiliation.component.scss']
})

export class FloridaAffiliationComponent implements OnInit {
  affiliationsJar = 'AFFILIATIONS_jar';
  profileAffiliations;
  siteId;
  universalContent;
  isFloridaResidentProfile: boolean = false;
  isFloridaResidentAffl: boolean = false;
  hasClickedButton: boolean = false;
  showComponent: boolean = true;
  targetAffiliationContent = {
    buttons: { },
    buttonPress: (val) => {},
    text: "",
    icon: "",
    confirmationText: ""
  };
  environment;
  iconUrl;
  confirmationButtons = {};
  authToken: string;
  isConfirmedFlResident: boolean = false;

  constructor(
    private resourceBundleService: ResourceBundleService,
    private cookieService: CookieService,
    private configService: ConfigService,
    private httpClient: HttpClient,
  ) {}

  async ngOnInit() {
    if (document.body.classList.contains('showfast')) {
      return;
    }
    
    const langFromOrigin = getLangFromOrigin();
    if (langFromOrigin !== 'en-us' && langFromOrigin !== 'es-us') {
      this.isFloridaResidentProfile = false;
      this.isFloridaResidentAffl = false;

      return;
    }

    document.addEventListener('navigation', async(event: any) => {
      if (event.detail.event === 'oneId-init' &&
          !!window.PEP?.PROFILE_AUTH
      ) {
        window.PEP.PROFILE_AUTH.getGuest()
          .then(async guestObj => {
            this.authToken = guestObj.token.access_token;
          });
      }
    });

    this.clearExpiredFLRes();
    const SWID = this.cookieService.get('SWID');
    //sample swid with FL res 22D1475A-4F84-4BFB-8D86-219B494CF5A2
    //sample swid with no FL res 3D6179F4-6E78-42E3-8C6B-B0903EDB0FE4
    // const SWID = '3D6179F4-6E78-42E3-8C6B-B0903EDB0FE4';
    this.environment = this.configService.getValue('environment');
    const iconUrlPrefix = this.environment === 'production' ? '' : 'stage.';
    await this.resourceBundleService.get('universalContent').then(content => {
      if (content) {
        this.iconUrl = `https://${iconUrlPrefix}cdn1.parksmedia.wdprapps.disney.com/media/layout/icons/pep/florida-resident.svg`;
        this.universalContent = get(content, 'universalContent.targetAffiliations');
        this.targetAffiliationContent = get(content, 'universalContent.targetAffiliations.module', {});
        const confirmationButtonObject = get(content, 'universalContent.targetAffiliations.module.buttons', {});
        this.confirmationButtons = confirmationButtonObject;
      } else {
        console.log('Error fetching FL universal content');
      }
    }, (e) => console.error('Error in ngOnInit FL Affiliation: ', e));

    document.getElementById('confirmationText').style.display = 'none';
    const localStorageAffiliations = JSON.parse(localStorage.getItem(this.affiliationsJar) || '{}');
    const sessionStorageAffiliations = JSON.parse(sessionStorage.getItem(this.affiliationsJar) || '{}');
    const siteId = this.configService.getValue('siteId');
    const isLocalFLRes = localStorageAffiliations[siteId]?.storedAffiliations?.includes('FL_RESIDENT');
    const isSessionFLRes = sessionStorageAffiliations[siteId]?.storedAffiliations?.includes('FL_RESIDENT');
    // 1. If localStorage has FL_RES, ensure sessionStorage matches and do not display module
    if (isLocalFLRes) {
      if (!isSessionFLRes) {
        this.updateAffiliationsInStorage('FL_RESIDENT', siteId, SWID, 90);
      }
      this.isFloridaResidentProfile = true;
      this.isFloridaResidentAffl = true;
      this.hasClickedButton = true;
      this.showComponent = false;

      return;
    }

    // 2. If only sessionStorage has FL_RES, display the module
    if (!isLocalFLRes && isSessionFLRes) {
      this.showFLModule();

      return;
    }

    // 4. For logged-in users with CA geolocation but FL_RES profile, show prompt
    if (!isLocalFLRes && !isSessionFLRes && SWID && SWID.indexOf('{') === 0) {
      await this.checkProfileAffiliations(SWID, siteId);

      return;
    }
    // if no conditions were met to display the module, hide it
    this.showComponent = false;
  }

  showFLModule() {
    this.isFloridaResidentProfile = true;
    this.isFloridaResidentAffl = true;
    this.showTargetedAffl();
    this.showComponent = true;
  }

  clearExpiredFLRes() {
    const siteId = this.configService.getValue('siteId');
    const localStorageString = localStorage.getItem(this.affiliationsJar);

    if (localStorageString) {
      const affiliations = JSON.parse(localStorageString);

      // Check if FL_RESIDENT entry exists with an expiration date
      if (affiliations[siteId]?.storedAffiliations?.includes('FL_RESIDENT')) {
        const expirationDate = new Date(affiliations[siteId].expiration);
        const currentDate = new Date();

        // If the current date is past the expiration date, remove FL_RESIDENT from both storages
        if (currentDate > expirationDate) {
          // Remove FL_RESIDENT from localStorage
          affiliations[siteId].storedAffiliations = affiliations[siteId].storedAffiliations.filter(
            (aff) => aff !== 'FL_RESIDENT'
          );
          delete affiliations[siteId].expiration;
          localStorage.setItem(this.affiliationsJar, JSON.stringify(affiliations));

          // Remove FL_RESIDENT from sessionStorage
          const sessionAffiliations = JSON.parse(sessionStorage.getItem(this.affiliationsJar) || '{}');
          if (sessionAffiliations[siteId]?.storedAffiliations) {
            sessionAffiliations[siteId].storedAffiliations = sessionAffiliations[siteId].storedAffiliations.filter(
              (aff) => aff !== 'FL_RESIDENT'
            );
            delete sessionAffiliations[siteId].expiration;
            sessionStorage.setItem(this.affiliationsJar, JSON.stringify(sessionAffiliations));
          }

          console.log("FL_RESIDENT has expired and been removed from both local and session storage.");
        }
      }
    }
  }

  async checkProfileAffiliations(SWID: string, siteId: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.authToken}`,
      'Content-Type': 'application/json'
    });
    try {
      const profileAffiliations = await this.httpClient.get<ProfileAffiliation>(`/features/gam/${SWID}`, { headers }).toPromise();
      const geographicAffiliations = profileAffiliations?.affiliations?.filter((aff) => {
        return aff.category === 'GEOGRAPHIC' && aff.subType === 'FL_RESIDENT';
      });

      if (geographicAffiliations.length > 0) {
        this.showTargetedAffl();
      } else {
        this.showComponent = false;
      }
    } catch (error) {
      console.error('Error getting profile affiliations: ', error);
    }
  }

  showTargetedAffl() {
    this.hasClickedButton = false;
    this.targetAffiliationContent.buttonPress = (val) => this.onButtonClick(val);
  }

  onButtonClick(buttonSelection) {
    const SWID = this.cookieService.get('SWID');
    const siteId = this.configService.getValue('siteId');
    const affiliations = this.getAffiliationsFromSessionStorage();
    const isSessionFlres = affiliations[siteId]?.storedAffiliations?.includes('FL_RESIDENT');

    // Check if a reload is needed based on mismatches
    const requiresReload =
    (isSessionFlres && buttonSelection === 'STD_GST') ||  // Session has FL_RES but user clicks NO
    (!isSessionFlres && buttonSelection === 'FL_RESIDENT');  // Session doesn't have FL_RES but user clicks YES

    if (buttonSelection === 'FL_RESIDENT') {
      document.getElementById('confirmationText').style.display = 'block';
      this.showComponent = true;
      this.isConfirmedFlResident = true;  // Show confirmation message
      this.updateAffiliationsInStorage('FL_RESIDENT', siteId, SWID, 90);

      // Hide confirmation message after 5 seconds and then set hasClickedButton to true
      setTimeout(() => {
        this.isConfirmedFlResident = false;
        this.hasClickedButton = true;
        setTimeout(() => {
          this.showComponent = false;
        }, 1000);
      }, 5000);
    } else if (buttonSelection === 'STD_GST') {
      this.removeFloridaResidentAffiliation(affiliations, siteId);
      this.hasClickedButton = true;  // Hide component immediately on "No"
    }

    // Reload if there was a mismatch between session storage and the user's selection
    if (requiresReload) {
      window.location.reload();
    }
  }


  updateAffiliationsInStorage(affiliationType: string, siteId: string, SWID: string, daysToExpire: number): void {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    const affiliations = this.getAffiliationsFromSessionStorage();
    if (!affiliations[siteId]) {
      affiliations[siteId] = { storedAffiliations: [] };
    }
    if (!affiliations[siteId].storedAffiliations.includes(affiliationType)) {
      affiliations[siteId].storedAffiliations.push(affiliationType);
    }

    affiliations[siteId].SWID = SWID;
    affiliations[siteId].expiration = expirationDate.toISOString();

    const affiliationsString = JSON.stringify(affiliations);
    window.sessionStorage.setItem(this.affiliationsJar, affiliationsString);
    window.localStorage.setItem(this.affiliationsJar, affiliationsString);
  }

  removeFloridaResidentAffiliation(affiliations: any, siteId: string): void {
    if (affiliations[siteId]?.storedAffiliations) {
      affiliations[siteId].storedAffiliations = affiliations[siteId].storedAffiliations.filter(aff => aff !== 'FL_RESIDENT');
    }
    const affiliationsString = JSON.stringify(affiliations);
    window.sessionStorage.setItem(this.affiliationsJar, affiliationsString);
    window.localStorage.setItem(this.affiliationsJar, affiliationsString);
  }

  getAffiliationsFromSessionStorage(): any {
    return JSON.parse(window.sessionStorage.getItem(this.affiliationsJar) || '{}');
  }
  getAffiliationsFromLocalStorage(): any {
    return JSON.parse(window.localStorage.getItem(this.affiliationsJar) || '{}');
  }

  // Helper function to update session storage
  updateSessionStorage(affiliationType: string, siteId: string): void {
    const sessionAffiliations = window.sessionStorage[this.affiliationsJar] || '{}';
    let affiliations = JSON.parse(sessionAffiliations);

    if (!affiliations[siteId]) {
      affiliations[siteId] = { storedAffiliations: [] };
    }

    if (affiliations[siteId].storedAffiliations.indexOf(affiliationType) === -1) {
      affiliations[siteId].storedAffiliations.push(affiliationType);
    }

    // Convert affiliations to a string format to store in sessionStorage and window.
    const affiliationsString = JSON.stringify(affiliations);
    // Update session storage
    window.sessionStorage[this.affiliationsJar] = affiliationsString;
    // Update localStorage
    window.localStorage.setItem([this.affiliationsJar], affiliationsString);
  }

  // Helper function to add FL_RESIDENT affiliation
  addFloridaResidentAffiliation(affiliations: any, siteId: string): void {
    if (affiliations[siteId].storedAffiliations.indexOf('FL_RESIDENT') === -1) {
      affiliations[siteId].storedAffiliations.push('FL_RESIDENT');
    }
  }

}
interface ProfileAffiliation {
  affiliations: GeographicAffiliation[],
  links: Link
}
interface GeographicAffiliation {
  category: string;
  subType: string;
}
declare const window: any;
// Global PEP Object
declare let PEP: any;
