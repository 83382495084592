import { Component } from '@angular/core';
import { ImageTemplateType, StoryAbstractComponent } from '../shared/story-abstract.component';
import { get } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { WindowSizeService } from '@app/shared/services/window-size/window-size.service';
@Component({
  selector: 'pixie-fast-plan-your-vacation',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./fast-plan-your-vacation.component.scss']
})
export class FastPlanYourVacationComponent extends StoryAbstractComponent {
    constructor(sanitizer: DomSanitizer, cookieService: CookieService, private windowSizeService: WindowSizeService) {
        super(sanitizer, cookieService);
    }
    getTemplateBlockGroups(storyData) {

        const banner = [{
            'classes': 'full-desktop',
            'anchorName': 'fast-plan-your-vacation-banner',
            'blocks': []
        }];

        let key = '';
        let classes: string;
        let backgroundImageTemplates: ImageTemplateType[];
        let data;

        key = 'banner';
        classes = this.getCSSSectionClasses(get(storyData, `descriptions.${key}`, false));
        data =  get(storyData, `descriptions.${key}`, {});
        backgroundImageTemplates = [
            this.getImageTemplate(
                data.media.icon1,
                'first-icon'
            ),
            this.getImageTemplate(
                data.media.icon2,
                'second-icon'
            )
        ];
        const device = this.windowSizeService.getDevice();
        if (device === 'mobile') {
            backgroundImageTemplates.push(
                this.getImageTemplate(
                    data.media.icon3,
                    'third-icon'
                ),
                this.getImageTemplate(
                    data.media.icon4,
                    'fourth-icon'
                )
            );
        } else {
            backgroundImageTemplates.push(
                this.getImageTemplate(
                    data.media.icon4,
                    'fourth-icon'
                ),
                this.getImageTemplate(
                    data.media.icon3,
                    'third-icon'
                )
            );
        }
        

        const blockToAdd = {
            'analyticsTrackSeen' : this.createAnalyticsTrackSeen(storyData, 1, data.analyticsLinkId),
            'classes': classes,
                itemsWrapped: {
                    background: backgroundImageTemplates,
                    foreground: [
                        this.getTextTemplate(storyData.descriptions.banner.sections.title, false, 'title'),
                        {
                            type: 'link',
                            classes: storyData.descriptions.banner.sections.cta1Css,
                            href: storyData.descriptions.banner.sections.cta1Link,
                            target: get(storyData, 'descriptions.banner.sections.cta1Target', '_self'),
                            analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
                                2,
                                storyData.descriptions.banner.sections.cta1AnalyticsLinkId),
                            items: [
                                this.getTextTemplate(
                                    storyData.descriptions.banner.sections.cta1Text
                                )
                            ]
                        },
                        {
                            type: 'link',
                            classes: storyData.descriptions.banner.sections.cta2Css,
                            href: storyData.descriptions.banner.sections.cta2Link,
                            target: get(storyData, 'descriptions.banner.sections.cta2Target', '_self'),
                            ariaLabel: storyData.descriptions.banner.sections.title + ' - '
                                + storyData.descriptions.banner.sections.cta2Text,
                            analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
                                2,
                                storyData.descriptions.banner.sections.cta2AnalyticsLinkId),
                            items: [
                                this.getTextTemplate(
                                    storyData.descriptions.banner.sections.cta2Text
                                )
                            ]
                        }
                    ]
                }
                

        };

        banner[0].blocks.push(blockToAdd);

        return banner;
    }
}
